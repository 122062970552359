import * as React from "react"
import ReactHtmlParser from "react-html-parser"

import transform from "../../utils/htmlParser"
import { ContactUsProps } from "../../interfaces/ContactUsProps"
// import ContactForm from "../molecules/contactForm"

const ContactUs: React.FC<ContactUsProps> = ({ intro }) => {
  return (
    <div className="container mb-l">
      <div className="max-w-4xl mx-auto">
        {intro &&
          ReactHtmlParser(intro, {
            decodeEntities: true,
            transform,
          })}
      </div>
      {/* <ContactForm /> */}
    </div>
  )
}

export default ContactUs
