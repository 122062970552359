import * as React from "react"
import { PageProps, graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../layout"
import Heading from "../organisms/heading"
import ContactUs from "../organisms/contactUs"

type DataProps = {
  wpPage: {
    seo: any
    hero_section: any
    content: any
  }
}

const Contact: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { hero_section, content } = data.wpPage

  return (
    <Layout>
      <Seo post={data.wpPage} />
      <Heading data={hero_section} />
      <ContactUs intro={content} />
    </Layout>
  )
}

export const query = graphql`
  query ContactPageQuery($id: String) {
    wpPage(id: { eq: $id }) {
      nodeType
      title
      uri
      ...heroPageFields
      ...seoPagesFields
      content
    }
  }
`

export default Contact
